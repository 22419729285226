import { render, staticRenderFns } from "./TextArea.vue?vue&type=template&id=71b060fa&scoped=true&"
import script from "./TextArea.vue?vue&type=script&lang=js&"
export * from "./TextArea.vue?vue&type=script&lang=js&"
import style0 from "./TextArea.vue?vue&type=style&index=0&id=71b060fa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b060fa",
  null
  
)

export default component.exports