import { InvoiceProduct } from './InvoiceProduct'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { InvoiceRepeatSettings } from './InvoiceRepeatSettings'

import { InvoiceAttachment } from './InvoiceAttachment'
import { RelationModel } from '@/globals/models/relation/Relation'
import { dateHelper } from '@/globals/helpers/DateHelper'

// @ts-ignore
const invoiceStatus = new InvoiceStatus().concept.value

export class Invoice {
  id: number|null = null
  to_relation: RelationModel|null = null
  to_relation_id: number|null = null
  from_relation_id: number|null = null
  prefix = ''
  prefix_id = '' as string|number
  reference = ''
  discount_value = 0.00
  discount_percentage = 0 as number
  description = ''
  invoice_date: string = new Date().toISOString().slice(0, 10)
  expiration_date = null as null|string
  status: number = invoiceStatus
  products: Array<InvoiceProduct> = []
  recurrence_id = null as number|null
  recurrence = new InvoiceRepeatSettings()
  total = 0.00
  total_ex_tax = 0.00
  amount_paid = 0.00
  reminder_sent = 0
  is_credit = false
  incl_tax = false
  updated_at = ''
  attachments: Array<InvoiceAttachment> = []

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.to_relation = data.to_relation
      this.to_relation_id = data.to_relation_id
      this.from_relation_id = data.from_relation_id
      this.prefix = data.prefix
      this.prefix_id = data.prefix_id
      this.reference = data.reference
      this.discount_value = data.discount_value
      this.discount_percentage = data.discount_percentage
      this.description = data.description
      this.invoice_date = data.invoice_date
      this.expiration_date = data.expiration_date
      this.status = data.status
      this.products = data.products
      this.recurrence_id = data.recurrence_id
      this.recurrence = data.recurrence ?? new InvoiceRepeatSettings()
      this.total = data.total
      this.total_ex_tax = data.total_ex_tax
      this.amount_paid = data.amount_paid
      this.reminder_sent = data.reminder_sent
      this.is_credit = data.is_credit
      this.incl_tax = data.incl_tax
      this.updated_at = data.updated_at
      this.attachments = data.attachments
    }
  }

  toRequest () {
    this.discount_percentage = this.discount_percentage ?? 0
    this.discount_value = this.discount_value ?? 0
    return this
  }

  fromResponse (data: any) {
    this.setData(data)

    if (data.to_relation) {
      this.to_relation = new RelationModel(data.to_relation)
    }

    this.invoice_date = new Date(data.invoice_date).toISOString().slice(0, 10)

    if (this.expiration_date) {
      this.expiration_date = new Date(data.expiration_date).toISOString().slice(0, 10)
    }

    const invoiceProducts = [] as Array<InvoiceProduct>
    if (data.products) {
      data.products.forEach((product: any) => {
        const invoiceProduct = new InvoiceProduct().fromResponse(product) as InvoiceProduct
        invoiceProducts.push(invoiceProduct)
      })
    }

    this.products = invoiceProducts

    this.recurrence = data.recurrence ? new InvoiceRepeatSettings().fromResponse(data.recurrence) : new InvoiceRepeatSettings()

    return this
  }

  getInvoiceNumber (relationPrefix: any, isFirstInvoice = false) {
    if (this.isStatusConcept()) {
      return 'concept' + (this.id ? '-' + this.id : '')
    } else if (this.isStatusRecurrenceTemplate()) {
      return 'template'
    }

    let number = this.prefix_id !== null ? this.prefix_id.toString() : ('concept' + (this.id ? '-' + this.id : ''))

    if (!isFirstInvoice) {
      number = this.prefix_id !== null ? this.prefix_id.toString().substring(1) : number
    }

    return (this.prefix ?? '') + number
  }

  isExpired (): boolean {
    const currentDate = dateHelper.toUnix(new Date())
    const expirationDateUnix = dateHelper.toUnix(new Date(this.expiration_date ?? ''))

    return this.expiration_date !== null && currentDate > expirationDateUnix
  }

  getOpenAmount (): number {
    return this.isStatusFullyPaid() ? 0 : this.total - this.amount_paid
  }

  isStatusNewConcept (): boolean { return this.id === null && this.status === new InvoiceStatus().concept.value }

  isStatusSavedConcept (): boolean { return this.id !== null && this.status === new InvoiceStatus().concept.value }

  isStatusConcept (): boolean { return this.status === new InvoiceStatus().concept.value }

  isStatusSent (): boolean { return this.status === new InvoiceStatus().sent.value }

  isStatusFullyPaid (): boolean { return this.status === new InvoiceStatus().fully_paid.value }

  isStatusPartiallyPaid (): boolean { return this.status === new InvoiceStatus().partially_paid.value }

  isStatusRecurrenceTemplate (): boolean { return this.status === new InvoiceStatus().recurrence_template.value }

  isRecurring (): boolean { return this.recurrence.active }

  isBaseInvoice (): boolean { return this.recurrence.base_invoice_id !== null && this.id === this.recurrence.base_invoice_id }

  isRecurrence (): boolean { return this.recurrence.id !== null && !this.isBaseInvoice() }
}
