import { render, staticRenderFns } from "./InvoiceComponent.vue?vue&type=template&id=6a8df0a6&scoped=true&"
import script from "./InvoiceComponent.vue?vue&type=script&lang=ts&"
export * from "./InvoiceComponent.vue?vue&type=script&lang=ts&"
import style0 from "./InvoiceComponent.vue?vue&type=style&index=0&id=6a8df0a6&prod&lang=scss&"
import style1 from "./InvoiceComponent.vue?vue&type=style&index=1&id=6a8df0a6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a8df0a6",
  null
  
)

export default component.exports