import { render, staticRenderFns } from "./AdministrationTwinfield.vue?vue&type=template&id=ef7b0390&scoped=true&"
import script from "./AdministrationTwinfield.vue?vue&type=script&lang=ts&"
export * from "./AdministrationTwinfield.vue?vue&type=script&lang=ts&"
import style0 from "./AdministrationTwinfield.vue?vue&type=style&index=0&id=ef7b0390&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef7b0390",
  null
  
)

export default component.exports