import { render, staticRenderFns } from "./DocumentsDataTable.vue?vue&type=template&id=6b392372&scoped=true&"
import script from "./DocumentsDataTable.vue?vue&type=script&lang=ts&"
export * from "./DocumentsDataTable.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentsDataTable.vue?vue&type=style&index=0&id=6b392372&prod&lang=scss&"
import style1 from "./DocumentsDataTable.vue?vue&type=style&index=1&id=6b392372&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b392372",
  null
  
)

export default component.exports