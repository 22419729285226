import { render, staticRenderFns } from "./SubmitButton.vue?vue&type=template&id=d52206be&scoped=true&"
import script from "./SubmitButton.vue?vue&type=script&lang=ts&"
export * from "./SubmitButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d52206be",
  null
  
)

export default component.exports