import { render, staticRenderFns } from "./CompanySettings.vue?vue&type=template&id=d19c375c&scoped=true&"
import script from "./CompanySettings.vue?vue&type=script&lang=ts&"
export * from "./CompanySettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d19c375c",
  null
  
)

export default component.exports